import React, { useRef, useState, useEffect } from 'react';

import Axios from 'axios';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import DownloadIcon from '@mui/icons-material/Download';
import FormHelperText from '@mui/material/FormHelperText';


import {
    Box,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Paper,
    Select,
    Skeleton,
    Stack,
    IconButton,
} from "@mui/material";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import ShowChartIcon from '@mui/icons-material/ShowChart';

import gistdaPic from '../imgs/gistda_logo.png';
import kuPic from '../imgs/ku.png';
import mhesiPic from '../imgs/mhesi.png';
import nrctPic from '../imgs/nrct.png';
import pcdPic from '../imgs/pcd.png';
import ccdcPic from '../imgs/ccdc.png';

import CircularProgress from '@mui/material/CircularProgress';


import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const FileDownload = require('js-file-download');

const Download = () => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const [admin, setAdmin] = React.useState('');

    const handleSelect = (event) => {
        setisLoading2(true);
        setAdmin(event.target.value);
        setisLoading2(false);
    };

    const handleChangePv = (event) => {
        setPv(event.target.value);
        setApl([]);
        setTbl([]);
        setAp(0);
        setTb(0);
        getApl(event.target.value);
        getTbl(0);
        console.log(pv);
    };

    const handleChangeAp = (event) => {
        setAp(event.target.value);
        setTbl([]);
        setTb(0);
        getTbl(event.target.value);

        console.log(ap);
    };

    const handleChangeTb = (event) => {
        setTb(event.target.value);
        console.log(tb);
    };

    const getPvl = async () => {
        const response = await fetch(
            urlPath + "/rest/getProvName"
        );
        const result = await response.json();

        let d = result.data

        d.unshift({
            "prov_t": "==ทั้งหมด==",
            "prov_idn": 0
        });

        setPvl(d);
        setIsLoadingPv(false);
    };

    const getApl = async (id) => {
        const response = await fetch(
            urlPath + "/rest/getAmpName?pv=" + id
        );
        const result = await response.json();

        let d = result.data;

        d.unshift({
            "amphoe_t": "==ทั้งหมด==",
            "amphoe_idn": 0
        });

        setApl(d);
        setIsLoadingAp(false);
    };

    const getTbl = async (id) => {
        const response = await fetch(
            urlPath + "/rest/getTamName?ap=" + id
        );
        const result = await response.json();

        let d = result.data;

        d.unshift({
            "tambon_t": "==ทั้งหมด==",
            "tambon_idn": 0
        });

        setTbl(result.data);
        setIsLoadingTb(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.screen.width > 700 ? 700 : 350,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const onKeyDown = (e) => {
        e.preventDefault();
    };


    const [fetchedData, setFetchedData] = useState([]);
    const [isLoading1, setisLoading1] = useState(false);
    const [isLoading2, setisLoading2] = useState(false);

    const [minMax, setMinMax] = useState([]);

    const [pvl, setPvl] = useState([]);
    const [apl, setApl] = useState([]);
    const [tbl, setTbl] = useState([]);
    const [pv, setPv] = useState(0);
    const [ap, setAp] = useState(0);
    const [tb, setTb] = useState(0);
    const [isLoadingPv, setIsLoadingPv] = useState(true);
    const [isLoadingAp, setIsLoadingAp] = useState(true);
    const [isLoadingTb, setIsLoadingTb] = useState(true);
    const [isLoadingFile, setIsLoadingFile] = useState(false);

    const [urlPath, setUrlPath] = useState('https://pm25.gistda.or.th');
    // const [urlPath, setUrlPath] = useState('http://127.0.0.1:5000');

    const getData = async (date) => {
        setisLoading1(true);
        // date = date.add(1, 'day')
        // console.log('fetch ' + JSON.stringify(date).split('T')[0].slice(1))
        const response = await fetch(
            urlPath + "/rest/getAvailableCSVInDate?dt=" + JSON.stringify(date).split('T')[0].slice(1)
        );
        const result = await response.json();

        setisLoading1(false);
        setFetchedData(
            result.data.map((time) => ({
                time: time.datetime
            }))
        );
        // console.log(fetchedData);
    };

    const getMinMax = async () => {

        const response = await fetch(
            urlPath + "/rest/getAvailableCSVDownloadDate"
        );
        const result = await response.json();

        setMinMax(result.data);
    };

    const [sDate, setsDate] = React.useState(null);
    const [eDate, seteDate] = React.useState(null);

    const handleLoad = async (date) => {
        // console.log(date)
        Axios({
            url: urlPath + '/rest/getPM25by' + admin + 'AsCSV?dt=' + date,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + admin + "_" + date.replace(" ", "_") + '.csv');
        });

    };

    const handleLoad_24 = async (date1, pv, ap, tb, ad) => {
        console.log(`${pv}  ${ap}  ${tb}  ${ad}`);

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb == 0) {
                    if (ap == 0) {
                        if (pv == 0) {
                            id = 0;
                        } else {
                            id = pv;
                            console.log(3)
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
                break;
            case 'Amphoe': {
                if (ap == 0) {
                    if (pv == 0) {
                        id = 0;
                    } else {
                        id = pv;
                        console.log(2)

                    }
                } else {
                    id = ap;
                }
            }
                break;
            case 'Province': {
                if (pv == 0) {
                    id = 0;
                } else {
                    id = pv;
                    console.log(1)

                }
            }
                break;
        }
        // console.log(id);
        await setIsLoadingFile(true);
        await Axios({
            url: urlPath + '/rest/getPM25by24hrs' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_24hrs_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });

    };

    const handleLoad_d = async (date1, pv, ap, tb, ad) => {

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb === 0) {
                    if (ap === 0) {
                        if (pv === 0) {
                            id = 0;
                        } else {
                            id = pv;
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
            case 'Amphoe': {
                if (ap === 0) {
                    if (pv === 0) {
                        id = 0;
                    } else {
                        id = pv;
                    }
                } else {
                    id = ap;
                }
            }
            case 'Province': {
                if (pv === 0) {
                    id = 0;
                } else {
                    id = pv;
                }
            }
        }
        // console.log(date)
        await setIsLoadingFile(true);
        await Axios({
            url: urlPath + '/rest/getPM25by1d' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_1d_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });
    };

    const handleLoad_30d = async (date1, pv, ap, tb, ad) => {

        let id = 0;
        switch (ad) {
            case 'Tambon': {
                if (tb === 0) {
                    if (ap === 0) {
                        if (pv === 0) {
                            id = 0;
                        } else {
                            id = pv;
                        }
                    } else {
                        id = ap;
                    }
                } else {
                    id = tb;
                }
            }
            case 'Amphoe': {
                if (ap === 0) {
                    if (pv === 0) {
                        id = 0;
                    } else {
                        id = pv;
                    }
                } else {
                    id = ap;
                }
            }
            case 'Province': {
                if (pv === 0) {
                    id = 0;
                } else {
                    id = pv;
                }
            }
        }
        // console.log(date)
        await setIsLoadingFile(true)
        await Axios({
            url: urlPath + '/rest/getPM25by1m' + ad + 'AsCSV?dt1=' + date1 + '&id=' + id,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'PM25_' + ad + "_1m_" + date1.replace(" ", "_") + '.csv');
            setIsLoadingFile(false);
        });

    };

    const thaiAdmin = () => {
        if (admin === 'Province') {
            return 'จังหวัด';
        } else if (admin === 'Amphoe') {
            return 'อำเภอ';
        } else if (admin === 'Tambon') {
            return 'ตำบล';
        } else {
            return '';
        }
    }

    useEffect(() => {
        getMinMax();
        setisLoading1(true);
        setisLoading2(true);

        const today = new dayjs(new Date());
        const dlevel = 'Province';
        setAdmin(dlevel);
        setsDate(today);
        seteDate(today);
        getData(today);
        setisLoading1(false);
        setisLoading2(false);
        getPvl();
        setPv(0);

        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    return (
        <div className="App" style={{ marginTop: 60 }}>
            {/* <h2>Height: {windowSize[1]}</h2> */}
            <Grid container spacing={{ xs: 1, md: 2 }} >

                <Grid item xs={12}>
                    <Item>

                        <Grid item xs={12} sx={{ paddingTop: "14px" }} >
                            <a href="https://www.mhesi.go.th/"><img src={mhesiPic} height={45}></img></a>
                            <a href="https://www.gistda.or.th/"><img src={gistdaPic} height={45}></img></a>
                            <a href="https://www.nrct.go.th/"><img src={nrctPic} height={45}></img></a>
                            {/* <a href="https://www.pcd.go.th/"><img src={pcdPic} height={45}></img></a> */}
                            <a href="https://www.cmuccdc.org/"><img src={ccdcPic} height={45}></img></a>
                            <a href="https://www.ku.ac.th/"><img src={kuPic} height={45}></img></a>
                        </Grid>


                        <Grid item xs={12} >
                            <Item><h1>ดาวน์โหลดข้อมูลจากระบบฐานข้อมูล PM2.5
                            </h1></Item>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Grid item xs={12} >

                        <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
                            <Stack align='left' direction="row" spacing={1}>
                                <Box sx={{ maxWidth: 250 }} align='center'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="เลือกวันที่"
                                            minDate={dayjs(minMax.min)}
                                            maxDate={dayjs(minMax.max)}
                                            value={sDate}
                                            onChange={(newValue) => {
                                                setsDate(newValue);
                                                getData(newValue);
                                                // console.log(newValue);
                                            }}
                                            format="YYYY-MM-DD"
                                            // renderInput={(params) => <TextField {...params} />}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box sx={{ maxWidth: 250 }} align='center'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <DatePicker
                                            label="เลือกวันที่สิ้นสุด"
                                            minDate={dayjs(minMax.min)}
                                            maxDate={dayjs(minMax.max)}
                                            value={eDate}
                                            slotProps={{
                                                textField: {
                                                    error: sDate > eDate,
                                                    helperText: `${sDate > eDate ? 'วันที่สิ้นสุดต้องไม่น้อยกว่าวันที่เริ่มต้น' : ''}`,
                                                },
                                            }}
                                            // onError={(err) => setDateError1(err)}
                                            onChange={(newValue) => {
                                                seteDate(newValue);
                                                getData(newValue);
                                                // console.log(newValue);
                                            }}
                                            format="YYYY-MM-DD"
                                            // renderInput={(params) => <TextField {...params} />}
                                            InputProps={{ readOnly: true }}
                                        /> */}
                                        {/* {eDate>sDate || 1 ? <FormHelperText>วันที่สิ้นสุดต้องไม่มาก่อนวันที่เริ่มต้น</FormHelperText>:""} */}
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                        </Grid>


                        {/* <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
                            <Box sx={{ maxWidth: 250 }} align='center'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">เลือกระดับเขตการปกครอง</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={admin}
                                        label="เลือกระดับเขตการปกครอง"
                                        onChange={handleSelect}
                                    >
                                        <MenuItem value={"Province"}>ระดับจังหวัด</MenuItem>
                                        <MenuItem value={"Amphoe"}>ระดับอำเภอ</MenuItem>
                                        <MenuItem value={"Tambon"}>ระดับตำบล</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid> */}
                        <Stack spacing={1} direction="row">

                            {isLoadingPv ? 'กำลังเชื่อมต่อฐานข้อมูล...' :

                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth >
                                        <InputLabel >จังหวัด</InputLabel>
                                        <Select
                                            value={pv}
                                            label="จังหวัด"
                                            onChange={handleChangePv}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                            defaultValue={0}
                                        >
                                            {pvl.map((data, i) => (
                                                <MenuItem value={data.prov_idn}>{data.prov_t}</MenuItem>
                                            ))}
                                        </Select>
                                        {pv ? '' : <FormHelperText>เลือกจังหวัด</FormHelperText>}
                                    </FormControl>
                                </Box>

                            }
                            {(isLoadingAp || isLoadingPv) ? '' :
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth >
                                        <InputLabel >{pv === '10' ? 'เขต' : 'อำเภอ'}</InputLabel>
                                        <Select
                                            value={ap}
                                            label={pv === '10' ? 'เขต' : 'อำเภอ'}
                                            onChange={handleChangeAp}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}

                                        >
                                            {apl.map((data, i) => (
                                                <MenuItem value={data.amphoe_idn}>{data.amphoe_t}</MenuItem>
                                            ))}
                                        </Select>
                                        {ap ? '' : <FormHelperText>เลือก{pv === '10' ? 'เขต' : 'อำเภอ'}</FormHelperText>}
                                    </FormControl>
                                </Box>
                            }
                            {(isLoadingTb || isLoadingAp || isLoadingPv) ? '' :
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth >
                                        <InputLabel >{pv === '10' ? 'แขวง' : 'ตำบล'}</InputLabel>
                                        <Select
                                            value={tb}
                                            label={pv === '10' ? 'แขวง' : 'ตำบล'}
                                            onChange={handleChangeTb}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}

                                        >
                                            {tbl.map((data, i) => (
                                                <MenuItem value={data.tambon_idn}>{data.tambon_t}</MenuItem>
                                            ))}
                                        </Select>
                                        {tb ? '' : <FormHelperText>เลือก{pv === '10' ? 'แขวง' : 'ตำบล'}</FormHelperText>}
                                    </FormControl>
                                </Box>
                            }
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={12} align='center'>
                        <TableContainer component={Paper} style={{ width: "100%" }}>
                            <Table size="small" aria-label="ccg sticky table" stickyHeader>
                                <TableHead>
                                    {isLoading2 ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell align="left" style={{ width: '70%' }}> <div>ข้อมูล ณ เวลา</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>เฉลี่ย 24 ชั่วโมงต่อเนื่อง (1 วัน)</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>รายวัน (1 วัน)</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>รายวัน (1 เดือน)</div> </TableCell>
                                        </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow >
                                        <TableCell align="left"> {'รายจังหวัด'}  </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pv, ap, tb, 'Province')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Province')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Province')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </StyledTableRow >
                                    <StyledTableRow >
                                        <TableCell align="left"> {'รายอำเภอ'}  </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pv, ap, tb, 'Amphoe')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Amphoe')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Amphoe')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </StyledTableRow >
                                    <StyledTableRow >
                                        <TableCell align="left"> {'รายตำบล'}  </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], pv, ap, tb, 'Tambon')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Tambon')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isLoadingFile} onClick={() => handleLoad_30d(sDate.toISOString().split('T')[0], pv, ap, tb, 'Tambon')} variant="contained">
                                                {isLoadingFile ? <CircularProgress size="24px"/> : <DownloadIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </StyledTableRow >
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* <TableContainer component={Paper} style={{ width: "100%" }}>
                            <Table size="small" aria-label="ccg sticky table" stickyHeader>
                                <TableHead>
                                    {isLoading2 ?
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell align="left" style={{ width: '70%' }}> <div>ข้อมูลระดับ{thaiAdmin()} ณ เวลา</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>ทั่วประเทศ ณ ชั่วโมง</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>เฉลี่ย 24 ชั่วโมงต่อเนื่อง</div> </TableCell>
                                            <TableCell align="center" style={{ width: '10%' }}> <div>รายวัน</div> </TableCell>
                                        </TableRow>
                                    }
                                </TableHead>

                                {(new Date(sDate)).toISOString().split('T')[0] === (new Date(eDate)).toISOString().split('T')[0] ?
                                    <TableBody>
                                        {(isLoading1 || isLoading2) || fetchedData.length === 0 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                            : fetchedData.map((time, index) => (
                                                <StyledTableRow >
                                                    <TableCell align="left"> {time.time.split(" ").join(' เวลา ') + ' น.'}  </TableCell>
                                                    <TableCell align="center" spacing={1}>
                                                        <IconButton disabled={(isLoading1 || isLoading2)} onClick={() => handleLoad(time.time)} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow >
                                            ))
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {(isLoading1 || isLoading2) || fetchedData.length === 0 ?
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton animation="wave" />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <StyledTableRow >
                                                <TableCell align="left"> {sDate.toISOString().split('T')[0]} ถึง {eDate.toISOString().split('T')[0]}  </TableCell>
                                                <TableCell align="center" spacing={1}>
                                                    <IconButton disabled variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_24(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton disabled={(!pv && admin === 'Province') || (!ap && admin === 'Amphoe') || (!tb && admin === 'Tambon')} onClick={() => handleLoad_d(sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0], admin === 'Province' ? pv : admin === 'Amphoe' ? ap : admin === 'Tambon' ? tb : '')} variant="contained">
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </StyledTableRow >

                                        }
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer> */}

                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: "7px" }}>
                    ติดต่อเรา Email: innotech@gistda.or.th
                </Grid>
            </Grid>


        </div >
    );
}

export default Download;